import React from 'react';
import HeaderToggle from './HeaderToggle';
import HeaderLogInOut from './HeaderLogInOut';
import HeaderNavItems from './HeaderNavItems';
import Logo from './research-black.svg';

const DesktopHeader = ({ setIsOpen, isOpen }) => (
    <section className='h-70px flex items-center justify-between mx-auto'>
        <div className='flex'>
            <a href='https://research.skift.com'>
                <img
                    src={Logo}
                    className='h-auto w-20'
                    alt='Skift Research Logo'
                />
            </a>
        </div>
        <nav className='w-full max-w-3xl mx-2.5 hidden wide:landscape:hidden sm:flex justify-around items-center gap-5'>
            <HeaderNavItems />
        </nav>
        <div className='hidden wide:landscape:hidden sm:block'>
            <HeaderLogInOut />
        </div>
        <HeaderToggle setIsOpen={setIsOpen} isOpen={isOpen} />
    </section>
);

export default DesktopHeader;
